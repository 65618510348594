import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
export const myTheme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: "black",
      },
    },
  },
  typography: {
    fontFamily: ['"Sintony"', "sans-serif"].join(", "),
    h1: {
      fontFamily: ['"Rufina"', "serif"].join(", "),
    },
    h2: {
      fontFamily: ['"Rufina"', "serif"].join(", "),
    },
    h3: {
      fontFamily: ['"Rufina"', "serif"].join(", "),
    },
  },
});

const Theme: React.FC = ({ children }) => {
  return <ThemeProvider theme={myTheme}>{children} </ThemeProvider>;
};

export default Theme;
