import { Box, ButtonBase, Container, Stack, Typography } from "@mui/material";
import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneIcon from "@mui/icons-material/Phone";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { myTheme } from "../utils/Theme";
import { RowFlexBox } from "../utils/layouts";
import { Link } from "gatsby";

export const ContactSection: React.FC<{ color: string }> = ({ color }) => {
  return (
    <React.Fragment>
      <RowFlexBox>
        <InstagramIcon sx={{ marginRight: myTheme.spacing(1), color }} />
        <ButtonBase
          component={Link as any}
          to="https://instagram.com/bylisangrino"
          target="_blank"
          rel="noopener"
        >
          <Typography color={color}>@bylisangrino</Typography>
        </ButtonBase>
      </RowFlexBox>
      <RowFlexBox>
        <EmailIcon sx={{ marginRight: myTheme.spacing(1), color }} />
        <Typography color={color}>lisangrino@icloud.com</Typography>
      </RowFlexBox>
      <RowFlexBox>
        <PhoneIcon sx={{ marginRight: myTheme.spacing(1), color }} />
        <Typography color={color}>+573006806755</Typography>
      </RowFlexBox>
    </React.Fragment>
  );
};
const Footer: React.FC<{ contactSection?: boolean }> = ({ contactSection }) => {
  return (
    <Box sx={{ backgroundColor: "#f7f6f6" }} paddingY={`${myTheme.spacing(4)}`}>
      <Container>
        <Stack spacing={1}>
          {contactSection && <ContactSection color="black" />}
          <RowFlexBox>
            <CopyrightIcon sx={{ marginRight: myTheme.spacing(1) }} />
            <Typography>Lisa Angrino 2022</Typography>
          </RowFlexBox>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
