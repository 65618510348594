export const intSizes = {
  xs: 2,
  s: 4,
  m: 8,
  l: 16,
  xl: 24,
};
function toPx(val: number): string {
  return `${val}px`;
}
export const sizes = {
  xs: toPx(intSizes.xs),
  s: toPx(intSizes.s),
  m: toPx(intSizes.m),
  l: toPx(intSizes.l),
  xl: toPx(intSizes.xl),
};
