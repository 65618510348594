import React from "react";

const SeoHelper: React.FC<{
  title: string;
  description: string;
  canonical: string;
}> = ({ title, description, canonical }) => {
  return (
    <React.Fragment>
      <link
        rel="canonical"
        key={`https://lisangrino.com/${canonical}`}
        href={`https://lisangrino.com/${canonical}`}
      />
      <meta name="description" content={description} />
      <meta name="image" content={"https://lisangrino.com/lisa.png"} />
      <meta property="og:url" content={`https://lisangrino.com/${canonical}`} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={"https://lisangrino.com/lisa.png"} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={"https://lisangrino.com/lisa.png"} />
    </React.Fragment>
  );
};
export function getSeoLinks({ path }: { path: string }) {
  return [{ rel: "canonical", href: `https://lisangrino.com/${path}` }];
}
export function getSeoMetas({
  title,
  description,
  path,
}: {
  title: string;
  description: string;
  path: string;
}) {
  return [
    {
      name: `description`,
      content: description,
    },
    {
      name: "image",
      content: "https://lisangrino.com/lisa.png",
    },
    {
      property: "og:url",
      content: `https://lisangrino.com/${path}`,
    },
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:description",
      content: description,
    },
    {
      property: "og:image",
      content: "https://lisangrino.com/lisa.png",
    },
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "twitter:title",
      content: title,
    },
    {
      name: "twitter:description",
      content: description,
    },
    {
      name: "twitter:image",
      content: "https://lisangrino.com/lisa.png",
    },
  ];
}

export default SeoHelper;
