import React from "react";
import Box, { BoxProps } from "@mui/material/Box";

export const RowFlexBox: React.FC<
  Omit<BoxProps, "display" | "flexDirection">
> = ({ children, ...props }) => {
  return (
    <Box display={"flex"} flexDirection="row" {...props}>
      {children}
    </Box>
  );
};
