import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ButtonBase from "@mui/material/ButtonBase";
import { sizes } from "../utils/constants";
import { RowFlexBox } from "../utils/layouts";
import { Link as GatsbyLink } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Menu, MenuItem as MuiMenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const NAV_BAR_HEIGHT_INT = 56;
const ALTERNATE_NAV_BAR_HEIGHT_INT = 86;
export const NAV_BAR_HEIGHT = `${NAV_BAR_HEIGHT_INT}px`;
export const ALTERNATE_NAV_BAR_HEIGHT = `${ALTERNATE_NAV_BAR_HEIGHT_INT}px`;

const BasicMenu: React.VFC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ButtonBase
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </ButtonBase>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MuiMenuItem onClick={handleClose}>
          <MenuItem to={"/paintings"} noMargin>
            paintings
          </MenuItem>
        </MuiMenuItem>
        <MuiMenuItem onClick={handleClose}>
          <MenuItem to={"/drawings"} noMargin>
            drawings
          </MenuItem>
        </MuiMenuItem>
        <MuiMenuItem onClick={handleClose}>
          <MenuItem to={"/feelings"} noMargin>
            feelings
          </MenuItem>
        </MuiMenuItem>
        <MuiMenuItem onClick={handleClose}>
          <MenuItem to={"/portraits"} noMargin>
            portraits 2022
          </MenuItem>
        </MuiMenuItem>
        <MuiMenuItem onClick={handleClose}>
          <MenuItem to={"/about"} noMargin>
            about
          </MenuItem>
        </MuiMenuItem>
      </Menu>
    </div>
  );
};

const MenuItem: React.FC<{ to: string; noMargin?: boolean }> = ({
  children,
  to,
  noMargin,
}) => {
  return (
    <ButtonBase
      component={GatsbyLink as any}
      to={to}
      activeStyle={{ textDecoration: "underline" }}
    >
      <Box
        sx={
          noMargin
            ? {}
            : {
                margin: { xs: sizes.l, lg: sizes.xl },
              }
        }
      >
        <Typography
          fontFamily={['"Rufina"', "serif"].join(", ")}
          fontSize={`1.25rem`}
        >
          {children}
        </Typography>
      </Box>
    </ButtonBase>
  );
};
export const AlternativeHeader = () => {
  return (
    <Box
      borderBottom={0}
      borderColor="black"
      position={"fixed"}
      width="100%"
      top={0}
      overflow="hidden"
      zIndex={10}
      bgcolor="white"
    >
      <Container>
        <RowFlexBox
          justifyContent="center"
          height={ALTERNATE_NAV_BAR_HEIGHT}
          alignItems="center"
        >
          <Box maxHeight={ALTERNATE_NAV_BAR_HEIGHT}>
            <StaticImage
              src="../images/signature.png"
              alt="Lisa Maria Angrino logo"
              height={ALTERNATE_NAV_BAR_HEIGHT_INT}
              objectFit={"contain"}
            />
          </Box>
        </RowFlexBox>
      </Container>
    </Box>
  );
};
const Header = () => {
  return (
    <Box
      borderBottom={0}
      borderColor="black"
      position={"fixed"}
      width="100%"
      top={0}
      overflow="hidden"
      zIndex={10}
      bgcolor="white"
    >
      <Container>
        <RowFlexBox
          justifyContent="space-between"
          height={NAV_BAR_HEIGHT}
          alignItems="center"
        >
          <ButtonBase to="/" component={GatsbyLink as any}>
            <Box maxHeight={NAV_BAR_HEIGHT}>
              <StaticImage
                src="../images/signature.png"
                alt="Lisa Maria Angrino logo"
                height={NAV_BAR_HEIGHT_INT}
                objectFit={"contain"}
              />
            </Box>
          </ButtonBase>
          <RowFlexBox
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
              },
            }}
          >
            <MenuItem to={"/paintings"}>paintings</MenuItem>
            <MenuItem to={"/drawings"}>drawings</MenuItem>
            <MenuItem to={"/feelings"}>feelings</MenuItem>
            <MenuItem to={"/portraits"}>portraits 2022</MenuItem>
            <MenuItem to={"/about"}>about</MenuItem>
          </RowFlexBox>
          <Box
            sx={{
              display: {
                xs: "block",
                sm: "block",
                md: "none",
              },
            }}
          >
            <BasicMenu />
          </Box>
        </RowFlexBox>
      </Container>
    </Box>
  );
};
export default Header;
